import _ from 'lodash';
import assertError from './assertError.js';
export function isIndexable(object) {
    return _.isObject(object);
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function getLiteralKeys(object) {
    // This is technically only a retyping of Object.keys
    return Object.keys(object);
}
export function checkKeys(object, expectedKeys) {
    if (!_.isObject(object)) {
        throw new TypeError('Given value must be an object');
    }
    const keys = Object.keys(object);
    const unexpectedKeys = _.difference(keys, expectedKeys);
    if (unexpectedKeys.length > 0) {
        throw new TypeError(`Given object contained unexpected keys: ${unexpectedKeys.toString()}`);
    }
    return true;
}
export function checkAttributeType(object, key, type, optional = false) {
    if (!optional && object[key] == null) {
        throw new TypeError(`Given object must have an attribute "${key}"`);
    }
    const actualType = getAttributeType(object[key]);
    if (actualType !== type && !(optional && object[key] == null)) {
        throw new TypeError(`Expected attribute "${key}" to be of type "${type}" but is of type "${actualType}" instead`);
    }
    return true;
}
/* istanbul ignore next */
export function endValidation() {
    // this should be unreachable
    throw TypeError('Given object is invalid!');
}
export function errorMap(array, transformer) {
    return array.map((el, i) => {
        try {
            return transformer(el);
        }
        catch (e) {
            assertError(e);
            const identification = getIdentification(el);
            if (identification != null) {
                throw new TypeError(`Error in element ${i} (${identification}): ${e.message}`);
            }
            else {
                throw new TypeError(`Error in element ${i}: ${e.message}`);
            }
        }
    });
}
export function nullSafe(func) {
    return function (p) {
        if (p === null) {
            return null;
        }
        if (p === undefined) {
            return undefined;
        }
        return func(p);
    };
}
const IDENTIFICATION_FIELDS = Object.freeze(['name', 'title', 'id']);
function getIdentification(o) {
    if (_.isObject(o)) {
        const indexableO = o;
        for (const identificationField of IDENTIFICATION_FIELDS) {
            const value = indexableO[identificationField];
            if (typeof value === 'string') {
                return `${identificationField}="${value}"`;
            }
        }
    }
    if (typeof o === 'string') {
        return `"${o}"`;
    }
    return null;
}
function getAttributeType(attribute) {
    if (Array.isArray(attribute)) {
        return 'array';
    }
    else {
        return typeof attribute;
    }
}

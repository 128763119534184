import isUUID from 'is-uuid';
import { v4 as uuidv4 } from 'uuid';
export function createRandomUUID() {
    return uuidv4();
}
export function createUUID(uuidStr) {
    if (!isUUID.v4(uuidStr)) {
        throw new TypeError(`"${uuidStr}" is not a validUUID`);
    }
    return uuidStr;
}
export function createUUIDFromUnknown(uuidSpec) {
    if (typeof uuidSpec !== 'string') {
        throw TypeError(`Expected type "string", got "${typeof uuidSpec}" instead!`);
    }
    return createUUID(uuidSpec);
}

import colorString from 'color-string';
import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import { createUUID } from '../util/uuid.js';
import { checkAttributeType, checkKeys, endValidation } from '../util/validation.js';
export function createColor(colorSpec) {
    const color = colorString.get(colorSpec);
    if (!color) {
        throw new Error(`The given color "${colorSpec}" is not a valid color value`);
    }
    return colorSpec;
}
export function createCategoryDefinition(categoryDefinitionSpec) {
    if (checkKeys(categoryDefinitionSpec, ['id', 'name', 'shortName', 'color', 'lightText']) &&
        checkAttributeType(categoryDefinitionSpec, 'id', 'string') &&
        checkAttributeType(categoryDefinitionSpec, 'name', 'string') &&
        checkAttributeType(categoryDefinitionSpec, 'shortName', 'string') &&
        checkAttributeType(categoryDefinitionSpec, 'color', 'string') &&
        checkAttributeType(categoryDefinitionSpec, 'lightText', 'boolean')) {
        const categoryDefinition = {
            id: createUUID(categoryDefinitionSpec.id),
            name: categoryDefinitionSpec.name.trim(),
            shortName: categoryDefinitionSpec.shortName.trim(),
            color: createColor(categoryDefinitionSpec.color),
            lightText: !!categoryDefinitionSpec.lightText,
        };
        return deepFreeze(categoryDefinition);
    }
    endValidation();
}
export function getCategoryMapping(left, right) {
    const leftByName = _.groupBy(left, (c) => normalizeCategoryDefinitionName(c.name));
    const rightByName = _.groupBy(right, (c) => normalizeCategoryDefinitionName(c.name));
    const names = [...Object.keys(leftByName), ...Object.keys(rightByName)];
    const leftToRight = {};
    const rightToLeft = {};
    for (const name of names) {
        const lids = leftByName[name]?.map((c) => c.id) ?? [];
        const rids = rightByName[name]?.map((c) => c.id) ?? [];
        for (const lid of lids) {
            leftToRight[lid] = rids;
        }
        for (const rid of rids) {
            rightToLeft[rid] = lids;
        }
    }
    return {
        leftToRight,
        rightToLeft,
    };
}
export function mergeCategoryLists(base, patch, { dropUnmatched }) {
    const { leftToRight: baseToPatch, rightToLeft: patchToBase } = getCategoryMapping(base, patch);
    const transformedPatch = patch
        .map((p) => {
        const bid = _.head(patchToBase[p.id]);
        const b = bid ? base.find((c) => c.id === bid) : undefined;
        if (b) {
            return {
                ...p,
                id: b.id,
            };
        }
        return p;
    })
        .filter((p) => p !== null);
    const unmatchedBase = dropUnmatched
        ? []
        : base.filter((c) => baseToPatch[c.id] === undefined || baseToPatch[c.id]?.length === 0);
    return [...transformedPatch, ...unmatchedBase];
}
export function normalizeCategoryDefinitionName(name) {
    return name.trim().toLowerCase();
}

import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import { createUUID, createUUIDFromUnknown } from '../util/uuid.js';
import { checkAttributeType, checkKeys, endValidation, errorMap, nullSafe } from '../util/validation.js';
import { getCategoryMapping } from './CategoryDefinition.js';
export function createOrder(orderSpec) {
    if (checkKeys(orderSpec, ['id', 'name', 'categoryOrder']) &&
        checkAttributeType(orderSpec, 'id', 'string') &&
        checkAttributeType(orderSpec, 'name', 'string') &&
        checkAttributeType(orderSpec, 'categoryOrder', 'array')) {
        const order = {
            id: createUUID(orderSpec.id),
            name: orderSpec.name.trim(),
            categoryOrder: errorMap(orderSpec.categoryOrder, resultNotUndefined(nullSafe(createUUIDFromUnknown))),
        };
        return deepFreeze(order);
    }
    endValidation();
}
export function sortItems(items, categoryOrder) {
    const categoriesPresent = _.chain(items)
        .map((i) => i.category)
        .filter((id) => typeof id === 'string')
        .sort()
        .sortedUniq()
        .value();
    return _.sortBy(items, [
        (item) => convertSmallerZeroToInf(categoryOrder.indexOf(undefinedToNull(item.category))),
        (item) => (item.category ? categoriesPresent.indexOf(item.category) : Infinity),
        getNameLowerCase,
        'id',
    ]);
}
export function sortCategories(categories, categoryOrder) {
    const categoryIteratee = (cat) => convertSmallerZeroToInf(categoryOrder.indexOf(cat.id));
    return _.sortBy(categories, categoryIteratee);
}
export function completeCategoryOrder(categoryOrder, categories) {
    const unknownRemovedCategoryOrder = categoryOrder.filter((cid) => cid === null || categories.some((c) => c.id === cid));
    const missingCategoryIds = categories.map((c) => c.id).filter((cid) => !unknownRemovedCategoryOrder.includes(cid));
    return [...unknownRemovedCategoryOrder, ...missingCategoryIds];
}
export function transformOrderToCategories(sourceOrder, sourceCategories, targetCategories) {
    const { leftToRight: sourceToTarget } = getCategoryMapping(sourceCategories, targetCategories);
    const mappedCategoryOrder = sourceOrder.categoryOrder
        .map((cid) => (cid === null ? cid : sourceToTarget[cid]))
        .filter((v) => v === null || (Array.isArray(v) && v.length > 0))
        .map((v) => (v === null ? v : v[0]));
    const categoryOrder = completeCategoryOrder(mappedCategoryOrder, targetCategories);
    return { ...sourceOrder, categoryOrder };
}
function undefinedToNull(input) {
    if (input === undefined) {
        return null;
    }
    return input;
}
function convertSmallerZeroToInf(index) {
    return index < 0 ? Infinity : index;
}
function resultNotUndefined(func) {
    return (a) => {
        const result = func(a);
        if (result === undefined) {
            throw new TypeError('The value may not be "undefined"!');
        }
        return result;
    };
}
function getNameLowerCase(named) {
    return named.name.toLowerCase();
}

import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import assertError from '../util/assertError.js';
import { createUUID } from '../util/uuid.js';
import { checkAttributeType, checkKeys, endValidation, errorMap, getLiteralKeys, isIndexable, nullSafe, } from '../util/validation.js';
import { createCategoryDefinition } from './CategoryDefinition.js';
import { createChange } from './Change.js';
import { createCompletionItem } from './Item.js';
import { createOrder } from './Order.js';
import { createShoppingList } from './ShoppingList.js';
export function createSyncedShoppingList(syncedShoppingListSpec, categories) {
    if (isIndexable(syncedShoppingListSpec)) {
        const shoppingList = createShoppingList(_.omit(syncedShoppingListSpec, ['token', 'changeId']), categories);
        if (checkKeys(syncedShoppingListSpec, [...getLiteralKeys(shoppingList), 'token', 'changeId']) &&
            checkAttributeType(syncedShoppingListSpec, 'token', 'string') &&
            checkAttributeType(syncedShoppingListSpec, 'changeId', 'string', true)) {
            const syncedShoppingList = {
                ...shoppingList,
                token: syncedShoppingListSpec.token,
                changeId: nullSafe(createUUID)(syncedShoppingListSpec.changeId),
            };
            return deepFreeze(syncedShoppingList);
        }
    }
    endValidation();
}
export function createSyncRequest(syncRequestSpec, transformItemSpec) {
    if (checkKeys(syncRequestSpec, [
        'previousSync',
        'currentState',
        'includeInResponse',
        'categories',
        'orders',
        'deleteCompletions',
        'addCompletions',
    ]) &&
        checkAttributeType(syncRequestSpec, 'previousSync', 'object') &&
        checkAttributeType(syncRequestSpec, 'currentState', 'object') &&
        checkAttributeType(syncRequestSpec, 'includeInResponse', 'array', true) &&
        checkAttributeType(syncRequestSpec, 'categories', 'array', true) &&
        checkAttributeType(syncRequestSpec, 'orders', 'array', true) &&
        checkAttributeType(syncRequestSpec, 'deleteCompletions', 'array', true) &&
        checkAttributeType(syncRequestSpec, 'addCompletions', 'array', true)) {
        let previousSync;
        try {
            previousSync = createSyncedShoppingList(syncRequestSpec.previousSync, null);
        }
        catch (e) {
            assertError(e);
            throw new TypeError(`Error in previousSync: ${e.message}`);
        }
        let currentState;
        try {
            currentState = createShoppingList(syncRequestSpec.currentState, null, transformItemSpec);
        }
        catch (e) {
            assertError(e);
            throw new TypeError(`Error in currentState: ${e.message}`);
        }
        let includeInResponse = undefined;
        if (syncRequestSpec.includeInResponse != null) {
            includeInResponse = errorMap(syncRequestSpec.includeInResponse, (s) => {
                if (typeof s !== 'string') {
                    throw TypeError('Element of includeInResponse must be of type "string"!');
                }
                return s;
            });
        }
        let categories = undefined;
        if (syncRequestSpec.categories != null) {
            try {
                categories = errorMap(syncRequestSpec.categories, createCategoryDefinition);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in categories: ${e.message}`);
            }
        }
        let orders = undefined;
        if (syncRequestSpec.orders != null) {
            try {
                orders = errorMap(syncRequestSpec.orders, createOrder);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in orders: ${e.message}`);
            }
        }
        let deleteCompletions = undefined;
        if (syncRequestSpec.deleteCompletions != null) {
            try {
                deleteCompletions = errorMap(syncRequestSpec.deleteCompletions, (c) => {
                    if (typeof c !== 'string') {
                        throw TypeError('Completion name must be string!');
                    }
                    return c;
                });
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in deleteCompletions: ${e.message}`);
            }
        }
        let addCompletions = undefined;
        if (syncRequestSpec.addCompletions != null) {
            try {
                addCompletions = errorMap(syncRequestSpec.addCompletions, createCompletionItem);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in addCompletions: ${e.message}`);
            }
        }
        return {
            previousSync,
            currentState,
            includeInResponse,
            categories,
            orders,
            deleteCompletions,
            addCompletions,
        };
    }
    endValidation();
}
export function createSyncResponse(syncResponseSpec) {
    if (checkKeys(syncResponseSpec, ['list', 'completions', 'categories', 'orders', 'changes']) &&
        checkAttributeType(syncResponseSpec, 'list', 'object') &&
        checkAttributeType(syncResponseSpec, 'completions', 'array', true) &&
        checkAttributeType(syncResponseSpec, 'categories', 'array', true) &&
        checkAttributeType(syncResponseSpec, 'orders', 'array', true) &&
        checkAttributeType(syncResponseSpec, 'changes', 'array', true)) {
        let list;
        try {
            list = createSyncedShoppingList(syncResponseSpec.list, null);
        }
        catch (e) {
            assertError(e);
            throw new TypeError(`Error in list: ${e.message}`);
        }
        let completions = undefined;
        if (syncResponseSpec.completions != null) {
            try {
                completions = errorMap(syncResponseSpec.completions, createCompletionItem);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in completions: ${e.message}`);
            }
        }
        let categories = undefined;
        if (syncResponseSpec.categories != null) {
            try {
                categories = errorMap(syncResponseSpec.categories, createCategoryDefinition);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in categories: ${e.message}`);
            }
        }
        let orders = undefined;
        if (syncResponseSpec.orders != null) {
            try {
                orders = errorMap(syncResponseSpec.orders, createOrder);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in orders: ${e.message}`);
            }
        }
        let changes = undefined;
        if (syncResponseSpec.changes != null) {
            try {
                changes = errorMap(syncResponseSpec.changes, createChange);
            }
            catch (e) {
                assertError(e);
                throw new TypeError(`Error in changes: ${e.message}`);
            }
        }
        return {
            list,
            completions,
            categories,
            orders,
            changes,
        };
    }
    endValidation();
}

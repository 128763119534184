import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import { checkAttributeType, checkKeys, endValidation, errorMap } from '../util/validation.js';
import { createItem, mergeItems, mergeItemsTwoWay } from './Item.js';
import { sortItems } from './Order.js';
export function createShoppingList(shoppingListSpec, categories, transformItemSpec) {
    if (checkKeys(shoppingListSpec, ['id', 'title', 'items']) &&
        checkAttributeType(shoppingListSpec, 'id', 'string') &&
        checkAttributeType(shoppingListSpec, 'title', 'string') &&
        checkAttributeType(shoppingListSpec, 'items', 'array')) {
        let items = errorMap(shoppingListSpec.items, (itemSpec) => createItem(itemSpec, transformItemSpec));
        const duplicatedIds = _.chain(items)
            .groupBy('id')
            .entries()
            .filter(([, items]) => items.length > 1)
            .map(([id]) => id)
            .value();
        if (duplicatedIds.length > 0) {
            throw new TypeError(`ShoppingList "${shoppingListSpec.title}" has duplicated ids: ${duplicatedIds.join(', ')}`);
        }
        if (categories != null) {
            items = sortItems(items, categories.map((cat) => cat.id));
        }
        const shoppingList = {
            id: shoppingListSpec.id,
            title: shoppingListSpec.title,
            items: items,
        };
        return deepFreeze(shoppingList);
    }
    endValidation();
}
export function mergeShoppingLists(base, client, server, categories) {
    let title;
    if (base.title != client.title) {
        title = client.title;
    }
    else {
        title = server.title;
    }
    const items = [];
    const baseMap = _.keyBy([...base.items], 'id');
    const clientMap = _.keyBy([...client.items], 'id');
    const serverMap = _.keyBy([...server.items], 'id');
    const allIds = _.union(_.keys(baseMap), _.keys(clientMap), _.keys(serverMap));
    for (const id of allIds) {
        const base = baseMap[id];
        const client = clientMap[id];
        const server = serverMap[id];
        if (base == null) {
            if (client != null && server != null) {
                items.push(mergeItemsTwoWay(client, server));
            }
            else if (client != null) {
                items.push(client);
            }
            else {
                // client == null && server != null
                items.push(server);
            }
        }
        else {
            if (client != null && server != null) {
                items.push(mergeItems(base, client, server));
            }
            else if (client != null) {
                mergeHandleDelete(items, base, client);
            }
            else if (server != null) {
                mergeHandleDelete(items, base, server);
            }
        }
    }
    return createShoppingList({
        // prefer id from the server to account for the fact that it may normalize the id (while clients will never change it)
        id: server.id,
        items,
        title,
    }, categories);
}
function mergeHandleDelete(items, base, remaining) {
    if (!_.isEqual(base, remaining)) {
        items.push(remaining);
    }
}

import { differenceInDays } from 'date-fns/differenceInDays';
import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import { createUUID } from '../util/uuid.js';
import { checkAttributeType, checkKeys, endValidation, errorMap, isIndexable } from '../util/validation.js';
import { createItem } from './Item.js';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export function createChange(changeSpec) {
    if (checkKeys(changeSpec, ['username', 'id', 'date', 'diffs']) &&
        checkAttributeType(changeSpec, 'username', 'string', true) &&
        checkAttributeType(changeSpec, 'id', 'string') &&
        checkAttributeType(changeSpec, 'date', 'string') &&
        checkAttributeType(changeSpec, 'diffs', 'array')) {
        const date = new Date(changeSpec.date);
        if (isNaN(date.getTime())) {
            throw new TypeError('Expected attribute "date" to be formatted as an ISO 8061 date');
        }
        const change = {
            username: changeSpec.username,
            id: createUUID(changeSpec.id),
            date: date,
            diffs: errorMap(changeSpec.diffs, createDiff),
        };
        return deepFreeze(change);
    }
    endValidation();
}
export function createDiff(diffSpec) {
    if (isIndexable(diffSpec)) {
        let diff;
        if (diffSpec.type === ADD_ITEM && checkKeys(diffSpec, ['type', 'item'])) {
            diff = {
                type: ADD_ITEM,
                item: createItem(diffSpec.item),
            };
        }
        else if (diffSpec.type === UPDATE_ITEM && checkKeys(diffSpec, ['type', 'oldItem', 'item'])) {
            diff = {
                type: UPDATE_ITEM,
                oldItem: createItem(diffSpec.oldItem),
                item: createItem(diffSpec.item),
            };
        }
        else if (diffSpec.type === DELETE_ITEM && checkKeys(diffSpec, ['type', 'oldItem'])) {
            diff = {
                type: DELETE_ITEM,
                oldItem: createItem(diffSpec.oldItem),
            };
        }
        else {
            throw new TypeError(`Unknown diff type ${String(diffSpec.type)}`);
        }
        return deepFreeze(diff);
    }
    endValidation();
}
export function getOnlyNewChanges(changes) {
    const now = new Date();
    // index of first change that is newer than 14 days
    const dateIndex = _.findIndex(changes, (c) => differenceInDays(now, c.date) < 14);
    // index of first change that is more than 200
    const lengthIndex = Math.max(0, changes.length - 200);
    // slice away the oldest changes
    return changes.slice(Math.min(dateIndex, lengthIndex), changes.length);
}
export function diffShoppingLists(oldShoppingList, newShoppingList) {
    const diffs = [];
    const oldMap = _.keyBy([...oldShoppingList.items], 'id');
    const newMap = _.keyBy([...newShoppingList.items], 'id');
    const allIds = _.union(_.keys(oldMap), _.keys(newMap));
    for (const id of allIds) {
        const oldItem = oldMap[id];
        const newItem = newMap[id];
        try {
            if (oldItem != null && newItem != null) {
                diffs.push(generateUpdateItem(oldShoppingList, newItem));
            }
            else if (newItem != null) {
                diffs.push(generateAddItem(newItem));
            }
            else {
                //  oldItem != null && newItem == null
                diffs.push(generateDeleteItem(oldShoppingList, id));
            }
        }
        catch (e) {
            // TypeError means that the diff couldn't be created, which means it isn't needed. We can safely ignore those.
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    }
    return diffs;
}
export function generateAddItem(newItem) {
    return {
        type: ADD_ITEM,
        item: newItem,
    };
}
export function generateUpdateItem(shoppingList, newItem) {
    const oldItem = shoppingList.items.find((item) => item.id === newItem.id);
    if (!oldItem) {
        throw TypeError(`Can't create update for item with id ${newItem.id}, it doesn't exist in list.`);
    }
    if (_.isEqual(oldItem, newItem)) {
        throw TypeError(`Can't create update for item with id ${newItem.id}, it is unchanged in the list.`);
    }
    return {
        type: UPDATE_ITEM,
        item: newItem,
        oldItem: oldItem,
    };
}
export function generateDeleteItem(shoppingList, itemid) {
    const oldItem = shoppingList.items.find((item) => item.id === itemid);
    if (!oldItem) {
        throw TypeError(`Can't create delete item with id ${itemid}, it doesn't exist in list.`);
    }
    return {
        type: DELETE_ITEM,
        oldItem: oldItem,
    };
}
export function applyDiff(shoppingList, diff) {
    switch (diff.type) {
        case ADD_ITEM: {
            const item = diff.item;
            if (shoppingList.items.some((i) => i.id === item.id)) {
                // TODO error type
                throw Error(`Can't apply diff, there already exists an item with id ${item.id}`);
            }
            return { ...shoppingList, items: [...shoppingList.items, item] };
        }
        case UPDATE_ITEM: {
            const index = _findOldItemIndex(shoppingList, diff.oldItem);
            const listItems = [...shoppingList.items];
            listItems[index] = diff.item;
            return { ...shoppingList, items: listItems };
        }
        case DELETE_ITEM: {
            const index = _findOldItemIndex(shoppingList, diff.oldItem);
            const listItems = [...shoppingList.items];
            listItems.splice(index, 1);
            return { ...shoppingList, items: listItems };
        }
    }
}
export function isDiffApplicable(shoppingList, diff) {
    try {
        applyDiff(shoppingList, diff);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function createReverseDiff(diff) {
    switch (diff.type) {
        case ADD_ITEM: {
            return {
                type: DELETE_ITEM,
                oldItem: diff.item,
            };
        }
        case UPDATE_ITEM: {
            return {
                type: UPDATE_ITEM,
                oldItem: diff.item,
                item: diff.oldItem,
            };
        }
        case DELETE_ITEM: {
            return {
                type: ADD_ITEM,
                item: diff.oldItem,
            };
        }
    }
}
export function createApplicableDiff(shoppingList, diff) {
    if (isDiffApplicable(shoppingList, diff)) {
        return diff;
    }
    switch (diff.type) {
        case ADD_ITEM: {
            const item = diff.item;
            const oldItemInList = shoppingList.items.find((i) => i.id === item.id);
            if (_.isEqual(oldItemInList, item)) {
                return null;
            }
            else {
                return generateUpdateItem(shoppingList, diff.item);
            }
        }
        case UPDATE_ITEM: {
            const oldItem = diff.oldItem;
            const oldItemInList = shoppingList.items.find((i) => i.id === oldItem.id);
            if (oldItemInList != null) {
                if (_.isEqual(oldItemInList, diff.item)) {
                    return null;
                }
                else {
                    return generateUpdateItem(shoppingList, diff.item);
                }
            }
            else {
                return generateAddItem(diff.item);
            }
        }
        case DELETE_ITEM: {
            const oldItem = diff.oldItem;
            const oldItemInList = shoppingList.items.find((i) => i.id === oldItem.id);
            if (oldItemInList != null) {
                return {
                    type: DELETE_ITEM,
                    oldItem: oldItemInList,
                };
            }
            else {
                return null;
            }
        }
    }
}
function _findOldItemIndex(shoppingList, oldItem) {
    const index = _.findIndex(shoppingList.items, (item) => _.isEqual(item, oldItem));
    if (index === -1) {
        throw Error(`Can't apply diff, old item not found in list`);
    }
    return index;
}

import deepFreeze from 'deep-freeze';
import escapeStringRegexp from 'escape-string-regexp';
import _ from 'lodash';
import * as mathjs from 'mathjs';
import assertError from '../util/assertError.js';
import { checkAttributeType, checkKeys, endValidation, nullSafe } from '../util/validation.js';
mathjs.createUnit('tbsp', {
    definition: '1 tablespoon',
    aliases: ['EL'],
});
mathjs.createUnit('tsp', {
    definition: '1 teaspoon',
    aliases: ['TL'],
});
export function createUnit(unitSpec) {
    const unit = unitSpec.trim(); // check if mathjs considers it a valid unit (will throw if not)
    mathjs.unit(unit);
    return unit;
}
export function createAmountValue(valueSpec) {
    if (Number.isNaN(valueSpec)) {
        throw new TypeError('AmountValue may not be NaN');
    }
    if (!Number.isFinite(valueSpec)) {
        throw new TypeError('AmountValue must be finite');
    }
    return valueSpec;
}
export function createAmount(amountSpec) {
    if (checkKeys(amountSpec, ['value', 'unit']) &&
        checkAttributeType(amountSpec, 'value', 'number') &&
        checkAttributeType(amountSpec, 'unit', 'string', true)) {
        const amount = {
            value: createAmountValue(amountSpec.value),
            unit: nullSafe(createUnit)(amountSpec.unit),
        };
        return deepFreeze(amount);
    }
    endValidation();
}
const unicodeVulgarFractions = {
    '½': '1/2',
    '⅓': '1/3',
    '⅔': '2/3',
    '¼': '1/4',
    '¾': '3/4',
    '⅕': '1/5',
    '⅖': '2/5',
    '⅗': '3/5',
    '⅘': '4/5',
    '⅙': '1/6',
    '⅚': '5/6',
    '⅐': '1/7',
    '⅛': '1/8',
    '⅜': '3/8',
    '⅝': '5/8',
    '⅞': '7/8',
    '⅑': '1/9',
    '⅒': '1/10',
};
const amountStringTransformations = [
    // comma as decimal seperator and semicolon as argument seperator
    (amountString) => amountString.replace(/,|;/g, (match) => (match == ',' ? '.' : ',')),
    // replace unicode vulgar fractions
    (amountString) => mapReplace(amountString, unicodeVulgarFractions),
    // replace possible mixed fractions with implicit addition
    // see https://github.com/josdejong/mathjs/issues/731
    (amountString) => amountString.replace(/(\d+)\s+(\d+)\/(\d+)/, '($1 + $2/$3)'),
];
const amountStringTransformationCombinations = powerSet(amountStringTransformations);
export function createAmountFromString(amountString) {
    // We store the error that occured during the first try and throw that in the end as it would
    // be confusing to receive the error for a transformed version.
    let initialError = null;
    for (const transformationCombination of amountStringTransformationCombinations) {
        const modAmountString = transformationCombination.reduce((memo, transformation) => transformation(memo), amountString);
        try {
            const evalResult = mathjs.evaluate(modAmountString);
            return mathjsValueToAmount(evalResult);
        }
        catch (e) {
            assertError(e);
            if (!initialError) {
                initialError = e;
            }
        }
    }
    if (initialError) {
        throw initialError;
    }
    else {
        throw TypeError("String couldn't be converted to Amount");
    }
}
export function createCookingAmount(amount) {
    const mathjsAmount = amountToMathjsValue(amount);
    if (!isMathjsUnit(mathjsAmount)) {
        return amount;
    }
    return mathjsValueToAmount(mathjsUnitToCookingMathjsUnit(mathjsAmount));
}
export function mergeAmounts(base, client, server) {
    if (_.isEqual(base, client)) {
        return server;
    }
    if (_.isEqual(base, server)) {
        return client;
    }
    return mergeAmountsTwoWay(client, server);
}
export function mergeAmountsTwoWay(client, server) {
    const mathjsClient = amountToMathjsValue(client);
    const mathjsServer = amountToMathjsValue(server);
    try {
        const comparison = mathjs.compare(mathjsClient, mathjsServer);
        if (typeof comparison === 'number' && comparison > 0) {
            return client;
        }
        else {
            return server;
        }
    }
    catch (e) {
        return client;
    }
}
export function getSIUnit(amount) {
    const mathjsValue = amountToMathjsValue(amount);
    if (isMathjsUnit(mathjsValue)) {
        return mathjsValue.toSI().formatUnits();
    }
    else {
        return null;
    }
}
export function addAmounts(a1, a2) {
    const mv1 = amountToMathjsValue(a1);
    const mv2 = amountToMathjsValue(a2);
    const mres = mathjs.add(mv1, mv2);
    const normalizedResult = isMathjsUnit(mres) ? mathjsUnitToCookingMathjsUnit(mres) : mres;
    return mathjsValueToAmount(normalizedResult);
}
function mathjsValueToAmount(mathjsValue) {
    if (isMathjsUnit(mathjsValue)) {
        const unit = mathjsValue.formatUnits();
        return {
            value: createAmountValue(mathjsValue.toJSON().value),
            unit,
        };
    }
    if (isMathjsFraction(mathjsValue)) {
        return mathjsValueToAmount(mathjs.number(mathjsValue));
    }
    let value;
    if (typeof mathjsValue === 'number') {
        value = createAmountValue(mathjsValue);
    }
    else if (isMathjsBigNumber(mathjsValue)) {
        value = createAmountValue(mathjsValue.toNumber());
    }
    else {
        throw new TypeError('Argument is not a mathjs value that can be converted to Amount');
    }
    return {
        value: createAmountValue(value),
        unit: undefined,
    };
}
function amountToMathjsValue(amount) {
    if (amount == null) {
        return 1;
    }
    if (amount.unit != null) {
        return mathjs.unit(amount.value, amount.unit);
    }
    return amount.value;
}
function mathjsUnitToCookingMathjsUnit(mathjsUnit) {
    let cooking;
    if (mathjsUnit.equalBase(mathjs.unit('l'))) {
        cooking = mathjs.unit(mathjsUnit.to('l').toString());
    }
    else if (mathjsUnit.equalBase(mathjs.unit('kg'))) {
        cooking = mathjs.unit(mathjsUnit.to('kg').toString());
    }
    else {
        cooking = mathjsUnit;
    }
    // make mathjs choose a suitable prefix automagically
    const prefixed = mathjs.unit(cooking.toString());
    return prefixed;
}
export function mapReplace(str, replacements) {
    const regexpStr = Object.keys(replacements)
        .map((r) => escapeStringRegexp(r))
        .join('|');
    return str.replace(RegExp(regexpStr, 'g'), (match) => replacements[match]);
}
export function powerSet(list) {
    const resultLength = 2 ** list.length;
    const result = [];
    for (let i = 0; i < resultLength; i++) {
        // bit pattern of index determines which elements are included
        result.push(list.filter((el, elIndex) => i & (1 << elIndex)));
    }
    return result;
}
function isMathjsUnit(value) {
    return mathjs.typeOf(value) === 'Unit';
}
function isMathjsBigNumber(value) {
    return mathjs.typeOf(value) === 'BigNumber';
}
function isMathjsFraction(value) {
    return mathjs.typeOf(value) === 'Fraction';
}

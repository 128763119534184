import deepFreeze from 'deep-freeze';
import _ from 'lodash';
import * as mathjs from 'mathjs';
import { createUUID } from '../util/uuid.js';
import { checkAttributeType, checkKeys, endValidation, isIndexable, nullSafe } from '../util/validation.js';
import { createAmount, createAmountFromString, mergeAmounts, mergeAmountsTwoWay } from './Amount.js';
import { getCategoryMapping } from './CategoryDefinition.js';
export function createCompletionItem(completionItemSpec) {
    if (checkKeys(completionItemSpec, ['name', 'category']) &&
        checkAttributeType(completionItemSpec, 'name', 'string') &&
        checkAttributeType(completionItemSpec, 'category', 'string', true)) {
        const item = {
            name: completionItemSpec.name.trim(),
            category: nullSafe(createUUID)(completionItemSpec.category),
        };
        return deepFreeze(item);
    }
    endValidation();
}
export function createLocalItem(localItemSpecArg, transformItemSpec) {
    const localItemSpec = transformItemSpec ? transformItemSpec(localItemSpecArg) : localItemSpecArg;
    if (isIndexable(localItemSpec)) {
        const completionItem = createCompletionItem(_.omit(localItemSpec, ['amount']));
        const localItem = {
            ...completionItem,
            amount: nullSafe(createAmount)(localItemSpec.amount),
        };
        return deepFreeze(localItem);
    }
    endValidation();
}
export function createLocalItemFromString(stringRepresentation, categories) {
    let category = undefined;
    const categoryResult = /^\s*\(([^)]+)\)(.*)$/u.exec(stringRepresentation);
    if (categoryResult != null) {
        const shortName = categoryResult[1];
        if (shortName === '?') {
            category = null;
            stringRepresentation = categoryResult[2];
        }
        else {
            const categoryCandidate = categories.find((cat) => cat.shortName.toUpperCase() == shortName.toUpperCase());
            if (categoryCandidate) {
                category = categoryCandidate.id;
                stringRepresentation = categoryResult[2];
            }
        }
    }
    const split = stringRepresentation.trim().split(/\s+/);
    for (let i = split.length; i > 0; i--) {
        const str = split.slice(0, i).join(' ');
        try {
            const amount = createAmountFromString(str);
            return createLocalItem({
                name: split.slice(i, split.length).join(' '),
                amount: amount,
                category: category,
            });
        }
        catch (e) {
            // ignore and try again
        }
    }
    return createLocalItem({
        name: split.join(' '),
        category: category,
    });
}
export function createLocalItemFromItemStringRepresentation(itemStringRepresentation, categories) {
    if (checkKeys(itemStringRepresentation, ['stringRepresentation']) &&
        checkAttributeType(itemStringRepresentation, 'stringRepresentation', 'string')) {
        return createLocalItemFromString(itemStringRepresentation.stringRepresentation, categories);
    }
    endValidation();
}
export function createItem(itemSpecArg, transformItemSpec) {
    const itemSpec = transformItemSpec ? transformItemSpec(itemSpecArg) : itemSpecArg;
    if (isIndexable(itemSpec)) {
        const localItem = createLocalItem(_.omit(itemSpec, ['id']));
        if (checkAttributeType(itemSpec, 'id', 'string')) {
            const item = {
                ...localItem,
                id: createUUID(itemSpec.id),
            };
            return deepFreeze(item);
        }
    }
    endValidation();
}
export function createItemFromItemStringRepresentation(itemStringRepresentation, categories) {
    if (isIndexable(itemStringRepresentation)) {
        const localItem = createLocalItemFromItemStringRepresentation(_.omit(itemStringRepresentation, ['id']), categories);
        if (checkAttributeType(itemStringRepresentation, 'id', 'string')) {
            const item = {
                ...localItem,
                id: createUUID(itemStringRepresentation.id),
            };
            return deepFreeze(item);
        }
    }
    endValidation();
}
export function itemToString(item) {
    const name = item.name != null ? item.name.trim() : '';
    const amount = item.amount;
    if (amount != null) {
        const unit = amount.unit;
        if (unit != null) {
            return `${mathjs.round(amount.value, 2)} ${unit.trim()} ${name}`;
        }
        else {
            return `${mathjs.round(amount.value, 2)} ${name}`;
        }
    }
    else {
        return name;
    }
}
export function mergeItems(base, client, server) {
    /**
     * Assumption: Item doesn't change identity, that is no changes that make new completely unrelated to old. In that case, changes to name
     * add information. We assume that length correlates with amount of information, therefore prefer the longer title
     */
    let name;
    if (base.name != client.name && base.name != server.name) {
        if (client.name.length < server.name.length) {
            name = server.name;
        }
        else {
            name = client.name;
        }
    }
    else if (base.name != client.name) {
        name = client.name;
    }
    else {
        name = server.name;
    }
    // Client change wins if it exists
    let category;
    if (base.category != client.category) {
        category = client.category;
    }
    else {
        category = server.category;
    }
    return {
        id: base.id,
        name,
        category,
        amount: mergeAmounts(base.amount, client.amount, server.amount),
    };
}
export function mergeItemsTwoWay(client, server) {
    let name;
    if (client.name.length < server.name.length) {
        name = server.name;
    }
    else {
        name = client.name;
    }
    return {
        id: client.id,
        name,
        category: client.category,
        amount: mergeAmountsTwoWay(client.amount, server.amount),
    };
}
export function addMatchingCategory(item, completions) {
    const exactMatchingCompletion = completions.find((completionItem) => item.name === completionItem.name && (item.category === undefined || item.category === completionItem.category));
    if (exactMatchingCompletion != null) {
        return addCompletionToItem(item, exactMatchingCompletion);
    }
    const normalizedItemName = normalizeCompletionName(item.name);
    const matchingCompletion = completions.find((completionItem) => normalizedItemName === normalizeCompletionName(completionItem.name) &&
        (item.category === undefined || item.category === completionItem.category));
    if (matchingCompletion != null) {
        return addCompletionToItem(item, matchingCompletion);
    }
    // Match ignoring everything after the first "(", to allow items like "Eggs (large)" to get the completions for "Eggs"
    const [head, ...rest] = item.name.split(/(?=\s*\()/);
    const tail = rest.join('');
    if (head && tail) {
        const normalizedHead = normalizeCompletionName(head);
        const looseMatchingCompletion = completions.find((completionItem) => normalizedHead === normalizeCompletionName(completionItem.name) &&
            (item.category === undefined || item.category === completionItem.category));
        if (looseMatchingCompletion != null) {
            return { ...addCompletionToItem(item, looseMatchingCompletion), name: looseMatchingCompletion.name + tail };
        }
    }
    return item;
}
function addCompletionToItem(item, matchingCompletion) {
    return Object.assign({}, item, _.omitBy(matchingCompletion, (val) => val == null));
}
export function normalizeCompletionName(name) {
    return name.trim().toLowerCase();
}
export function transformItemsToCategories(sourceItems, sourceCategories, targetCategories) {
    const { leftToRight: sourceToTarget } = getCategoryMapping(sourceCategories, targetCategories);
    return sourceItems.map((i) => {
        if (!i.category) {
            return i;
        }
        const mapped = sourceToTarget[i.category];
        return {
            ...i,
            category: _.head(mapped) ?? i.category,
        };
    });
}
